<template>
  <div class="nav-h5">
    <div class="options">
      <template v-if="hasLogin">
        <div class="option-item  option-active"  @click="gotoHome">Home</div>
        <div class="option-line"></div>
      </template>
      <template v-else>
        <div class="option-item  option-active" @click="gotoPool">PooL</div>
         <div class="option-line"></div>
      </template>
      <!--
      <a class="option-item" href="javascript:;">qqgroup636081273</a>
      <div class="option-line"></div>
      <a class="option-item" href="https://t.me/joinchat/C98Yl7Bjo8M2ZGI1" target="_blank">
      <br>TelegramLink</a>
      <div class="option-line"></div>

      <div class="option-item" @click="gotoHelp">CHN-Tutorial</div>
     
      <div class="option-line"></div>
       -->
      
      <a class="option-item" href="https://docs.google.com/document/d/1fkJQ1BRT1eU1yptm_nLSyA5qnXgd7hxL-rltCsQq6UI/edit" target="_blank" >Tutorial</a>
      <div class="option-line"></div>
      <a class="option-item" href="mailto: gaeapool@gmail.com">gaeapool@gmail.com</a>

    </div>
    <div class="account">
      <div class="login" v-if="hasLogin">
        <span class="username">{{ userName }}</span>
        <span class="btn btn-default" @click="logout">logout</span>
      </div>
      <div class="login" v-else>
        <span class="btn btn-noborder mr13" @click="gotoLogin">Log in</span>
        <span class="btn btn-default" @click="gotoSignUp">Sign Up</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login/signIn");
    },
    gotoSignUp() {
      this.$router.push("/login/signUp");
    },
    logout() {
      this.$store.dispatch("logout");
    },
    gotoHelp() {
        window.open('https://docs.qq.com/doc/DYkpZZXB5Z3ZvdXBl')
    },
    gotoHome() {
        this.$router.push('/my')
    },
    gotoPool() {
        this.$router.push('/')
    }
  },
  computed: {
    hasLogin() {
      return this.$store.getters.logined;
    },
    userName() {
      return this.$store.username;
    },
  },
};
</script>
<style lang="less">
.nav-h5 {
  box-sizing: border-box;
  width: 750px;
  height: 100%;
  background: #101010;
  border: 1px solid #707070; /*no*/
  color: #fff;
  font-size: 36px;
  line-height: 43px;
  padding-top: 200px;
  text-align: center;
  .option-item {
    height: 43px;
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    color: #fff;
    text-decoration: none;
  }
  .option-active {
    color: #893eff;
  }
  .option-line {
    background: #893eff;
    width: 40px;
    height: 4px;
    margin: 60px auto;
  }
  .account {
    margin-top: 255px;
    .btn {
      display: inline-block;
      width: 173px;
      height: 49px;
      border: 1px solid #893eff;
      opacity: 1;
      margin: 0 14px;
      font-size: 24px;
      line-height: 50px;
    }
    .btn-noborder {
      border: none;
    }
    .username {
      font-size: 24px;
      line-height: 50px;
    }
  }
}
</style>
